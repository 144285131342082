<template>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-row :gutter="20" class="mg-t-5 row-custom-dashboard">
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <div class="is-flex js-between">
              <h2 class="mg-0">นำเสนอ</h2>
              <h2 class="mg-0" v-if="dataOffers.length > 0">
                รวม {{ sumTotal(dataOffers) }} ราย
              </h2>
            </div>
            <TreeMap
              :data="dataOffers"
              :color="colorChart"
              :fetchChart="fetchChart"
              v-if="dataOffers.length > 0"
            />
            <h2 v-else class="text-center">ไม่มีข้อมูลนำเสนอ</h2>
          </div>
        </el-col>
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <div class="is-flex js-between">
              <h2 class="mg-0">สนใจ</h2>
              <h2 class="mg-0" v-if="dataInterest.length > 0">
                รวม {{ sumTotal(dataInterest) }} ราย
              </h2>
            </div>
            <TreeMap
              :data="dataInterest"
              :color="colorChart"
              :fetchChart="fetchChart"
              v-if="dataInterest.length > 0"
            />
            <div v-else class="nodata">
              <h2 class="mg-0">ไม่มีข้อมูลสนใจ</h2>
            </div>
          </div>
        </el-col>
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <el-row :gutter="20" class="row-custom-dashboard in-side">
              <el-col :span="24">
                <h2 class="mg-0">สถานะ Pipeline</h2>
                <GroupPie
                  :dataFetch="dataPipeline"
                  :color="colorChart"
                  :fetchChart="fetchChart"
                  v-if="dataPipeline.length > 0"
                />
                <div v-else class="nodata">
                  <h2 class="mg-0">ไม่มีข้อมูลสถานะ Pipeline</h2>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <el-row :gutter="20" class="row-custom-dashboard in-side">
              <el-col :span="24">
                <h2 class="mg-0">ผลการติดต่อลูกค้า</h2>
                <GroupPie
                  :dataFetch="dataContact"
                  :color="colorChart"
                  :fetchChart="fetchChart"
                  v-if="dataContact.length > 0"
                />
                <div v-else class="nodata">
                  <h2 class="mg-0">ไม่มีข้อมูลผลการติดต่อลูกค้า</h2>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import GroupPie from "@/components/dashboard/GroupPie";
import TreeMap from "@/charts/TreeMap";
import { colorChart } from "@/colors/color.json";
import { mapState } from "vuex";

export default {
  props: ["fetchChart"],
  props: {
    fetchChart: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GroupPie,
    TreeMap,
  },
  mounted() {
    this.setData();
  },
  watch: {
    insurance() {
      this.setData();
    },
  },
  data() {
    return {
      widthMax: window.innerWidth,
      color: ["#3598FF", "#FF646A", "#38D85B"],
      colorChart: colorChart,
      dataOffers: [],
      dataInterest: [],
      dataPipeline: [],
      dataContact: [],
      sumApprove: 0,
    };
  },
  methods: {
    async setData() {
      await this.setDataOffers();
      await this.setDataInterest();
      await this.setDataPipeline();
      await this.setDataContact();
    },
    sumTotal(dataSum) {
      let total = 0;
      dataSum.forEach((row) => {
        total += Number(row.value);
      });
      return total;
    },
    async setDataOffers() {
      this.dataOffers = !!this.insurance.offers ? this.insurance.offers : [];
    },
    async setDataInterest() {
      this.dataInterest = !!this.insurance.interests
        ? this.insurance.interests
        : [];
    },
    async setDataPipeline() {
      let pipelines = !!this.insurance.pipeline ? this.insurance.pipeline : [];
      const total = pipelines.reduce((sum, row) => (sum += row.value), 0);
      this.dataPipeline = await pipelines.map((row) => ({
        ...row,
        percent: this.totalPercent(row.value, total),
      }));
    },
    async setDataContact() {
      let contacts = !!this.insurance.contact ? this.insurance.contact : [];
      const total = contacts.reduce((sum, row) => (sum += row.value), 0);
      this.dataContact = await contacts.map((row) => ({
        ...row,
        percent: this.totalPercent(row.value, total),
      }));
    },
  },
  computed: {
    ...mapState({
      insurance: (state) => state.dashboard.insurance,
    }),
  },
};
</script>
