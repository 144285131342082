<template>
  <el-row :gutter="20">
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">นำเสนอ</h2>
            <div style="width: 100%; overflow-x: auto">
              <BarCharts
                :showName="false"
                :width="widthMax < 1200 ? `1120px` : ''"
                :barWidth="`25px`"
                :label="dataOffer[0].name"
                :height="'300px'"
                :data="dataOffer[0].data"
                :fetchChart="fetchChart"
                v-if="dataOffer.length > 0"
              />
              <h2 v-else class="text-center">ไม่มีข้อมูลนำเสนอ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">สนใจ</h2>
            <div style="width: 100%; overflow-x: auto">
              <BarCharts
                :showName="false"
                :width="widthMax < 1200 ? `1120px` : ''"
                :barWidth="`25px`"
                :label="dataInterest[0].name"
                :height="'300px'"
                :data="dataInterest[0].data"
                :fetchChart="fetchChart"
                v-if="dataInterest.length > 0"
              />
              <h2 v-else class="text-center">ไม่มีข้อมูลที่สนใจ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">สถานะ Pipeline</h2>
            <div style="width: 100%; overflow-x: auto">
              <BarChartsGroupCustom
                :productName="[
                  'อยู่ระหว่างการพิจารณา/ รอข้อมูลจากลูกค้า',
                  'ได้รับเอกสารและข้อมูลเบื้องต้น',
                  'อนุมัติกรมธรรม์',
                ]"
                :width="widthMax < 1200 ? `1120px` : ''"
                :data="dataPipeline"
                :barWidth="`25px`"
                :height="'300px'"
                :color="color"
                :fetchChart="fetchChart"
                v-if="dataPipeline.length > 0"
                pageType="tabIndustry"
              />
              <h2 v-else class="text-center">ไม่มีข้อมูลสถานะ Pipeline</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">ผลการติดต่อลูกค้า</h2>
            <div style="width: 100%; overflow-x: auto">
              <BarChartsGroupCustom
                :productName="[
                  'ติดต่อไม่ได้',
                  'ไม่สนใจบริการ',
                  'ขาดการติดต่อเกิน 3 เดือน',
                ]"
                :width="widthMax < 1200 ? `1120px` : ''"
                :data="dataContactStatus"
                :barWidth="`25px`"
                :height="'300px'"
                :color="color"
                :fetchChart="fetchChart"
                v-if="dataContactStatus.length > 0"
                pageType="tabIndustry"
              />
              <h2 v-else class="text-center">ไม่มีข้อมูลผลการติดต่อลูกค้า</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import BarCharts from "@/charts/BarChartsNormal";
import BarChartsGroupCustom from "@/charts/BarChartsGroupCustom";

export default {
  components: {
    BarCharts,
    BarChartsGroupCustom,
  },
  props: ["fetchChart", "dataFetch"],
  data() {
    return {
      dataOffer: [],
      dataInterest: [],
      dataPipeline: [],
      dataContactStatus: [],
      widthMax: window.innerWidth,
      color: ["#FAC858", "#5470C6", "#FF9900"],
    };
  },
  mounted() {
    this.setData();
  },
  watch: {
    dataFetch() {
      this.setData();
    },
  },
  methods: {
    setData() {
      this.setDataOffers();
      this.setDataInterest();
      this.setDataPipeline();
      this.setDataContactStatus();
    },
    async setDataOffers() {
      this.dataOffer = [];
      let setObj = [
        {
          data: [],
          name: [],
        },
      ];
      this.dataFetch.cateIndustryTypeOffers.sort((a, b) => {
        let modifier = -1;
        if (a.count < b.count) return -1 * modifier;
        if (a.count > b.count) return 1 * modifier;
        return 0;
      });
      if (this.dataFetch.cateIndustryTypeOffers.some((e) => e.count != 0)) {
        this.dataFetch.cateIndustryTypeOffers.forEach((e) => {
          if (e.count != 0) {
            setObj[0].data.push(e.count);
            setObj[0].name.push(e.label.replace("ทางการ", "ทางการ\n"));
          }
        });
        // let setObj = [
        //   {
        //     data: this.dataFetch.cateIndustryTypeOffers.map((e) => e.count),
        //     name: this.dataFetch.cateIndustryTypeOffers.map((e) =>
        //       e.label.replace("ทางการ", "ทางการ\n")
        //     ),
        //   },
        // ];
        this.dataOffer = setObj;
      }
    },
    setDataInterest() {
      this.dataInterest = [];
      let setObj = [
        {
          data: [],
          name: [],
        },
      ];
      this.dataFetch.cateIndustryTypeInterests.sort((a, b) => {
        let modifier = -1;
        if (a.count < b.count) return -1 * modifier;
        if (a.count > b.count) return 1 * modifier;
        return 0;
      });
      if (this.dataFetch.cateIndustryTypeInterests.some((e) => e.count != 0)) {
        this.dataFetch.cateIndustryTypeInterests.forEach((e) => {
          if (e.count != 0) {
            setObj[0].data.push(e.count);
            setObj[0].name.push(e.label.replace("ทางการ", "ทางการ\n"));
          }
        });
        this.dataInterest = setObj;
      }
    },

    setDataPipeline() {
      this.dataPipeline = [];
      let setObj = [];
      let total = 0;
      this.dataFetch.cateIndustryTypeApprove.forEach((row, index) => {
        if (row.approve > 0 || row.pending > 0 || row.basicInformation > 0) {
          setObj.push({
            product: row.label.replace("ทางการ", "ทางการ\n"),
            อนุมัติ: row.pending, // อยู่ระหว่างการพิจารณา/ รอข้อมูลจากลูกค้า
            อนุมัติมีเงื่อนไข: row.basicInformation, // ได้รับเอกสารและข้อมูลเบื้องต้น
            ไม่อนุมัติ: row.approve, //อนุมัติกรมธรรม์
          });
        }
        total += row.pending + row.basicInformation + row.approve;
      });
      if (total == 0) {
        this.dataPipeline = [];
      } else {
        // เพื่อให้กราฟอยู่ชิดซ้าย
        if (setObj.length < 6) {
          for (let index = setObj.length; index < 6; index++) {
            setObj.push({
              product: index,
            });
          }
        }
        this.dataPipeline = setObj;
      }
    },
    setDataContactStatus() {
      this.dataContactStatus = [];
      let setObj = [];
      let total = 0;
      this.dataFetch.cateIndustryTypeContact.forEach((row, index) => {
        if (
          row.cantContact > 0 ||
          row.notInterest > 0 ||
          row.cantContactThreeMonths > 0
        ) {
          setObj.push({
            product: row.label.replace("ทางการ", "ทางการ\n"),
            อนุมัติ: row.cantContact, // ติดต่อไม่ได้
            อนุมัติมีเงื่อนไข: row.notInterest, // ไม่สนใจบริการ
            ไม่อนุมัติ: row.cantContactThreeMonths, // ขาดการติดต่อเกิน 3 เดือน
          });
        }
        total += row.cantContact + row.notInterest + row.cantContactThreeMonths;
      });
      if (total == 0) {
        this.dataContactStatus = [];
      } else {
        // เพื่อให้กราฟอยู่ชิดซ้าย
        if (setObj.length < 6) {
          for (let index = setObj.length; index < 6; index++) {
            setObj.push({
              product: index,
            });
          }
        }
        this.dataContactStatus = setObj;
      }
    },
  },
};
</script>