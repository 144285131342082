var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{staticClass:"row-custom-dashboard",attrs:{"gutter":20}},[_c('el-col',{staticClass:"mg-t-5",attrs:{"span":24,"md":12}},[_c('div',{staticClass:"bg-white pd-y-5 pd-x-6"},[_c('el-row',{staticClass:"row-custom-dashboard in-side",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('h2',{staticClass:"mg-0"},[_vm._v("นำเสนอ")]),(_vm.dataOffer.length > 0)?_c('BarCharts',{attrs:{"barWidth":'50px',"label":['Small', 'Medium', 'Large'],"height":'300px',"data":_vm.dataOffer,"color":_vm.color,"fetchChart":_vm.fetchChart}}):_c('div',{staticClass:"nodata"},[_c('h2',{staticClass:"mg-0"},[_vm._v("ไม่มีข้อมูลนำเสนอ")])])],1)],1)],1)]),_c('el-col',{staticClass:"mg-t-5",attrs:{"span":24,"md":12}},[_c('div',{staticClass:"bg-white pd-y-5 pd-x-6"},[_c('el-row',{staticClass:"row-custom-dashboard in-side",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('h2',{staticClass:"mg-0"},[_vm._v("สนใจ")]),(_vm.dataInterest.length > 0)?_c('BarCharts',{attrs:{"barWidth":'50px',"label":['Small', 'Medium', 'Large'],"height":'300px',"data":_vm.dataInterest,"color":_vm.color,"fetchChart":_vm.fetchChart}}):_c('div',{staticClass:"nodata"},[_c('h2',{staticClass:"mg-0"},[_vm._v("ไม่มีข้อมูลที่สนใจ")])])],1)],1)],1)]),_c('el-col',{staticClass:"mg-t-5",attrs:{"span":24}},[_c('div',{staticClass:"bg-white pd-y-5 pd-x-6"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('h2',{staticClass:"mg-0"},[_vm._v("สถานะ Pipeline")]),(_vm.dataPipeline.length > 0)?_c('BarChartsCustom',{attrs:{"data":_vm.dataPipeline,"height":'300px',"label":['Small', 'Medium', 'Large'],"productName":[
              'อยู่ระหว่างการพิจารณา/ รอข้อมูลจากลูกค้า',
              'ได้รับเอกสารและข้อมูลเบื้องต้น',
              'อนุมัติกรมธรรม์',
            ],"color":_vm.color,"fetchChart":_vm.fetchChart}}):_c('h2',{staticClass:"text-center"},[_vm._v("ไม่มีข้อมูลสถานะ Pipeline")])],1)],1)],1)]),_c('el-col',{staticClass:"mg-t-5",attrs:{"span":24}},[_c('div',{staticClass:"bg-white pd-y-5 pd-x-6"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('h2',{staticClass:"mg-0"},[_vm._v("ผลการติดต่อลูกค้า")]),(_vm.dataContactStatus.length > 0)?_c('BarChartsCustom',{attrs:{"data":_vm.dataContactStatus,"height":'300px',"label":['Small', 'Medium', 'Large'],"productName":[
              'ติดต่อไม่ได้',
              'ไม่สนใจบริการ',
              'ขาดการติดต่อเกิน 3 เดือน',
            ],"color":_vm.color,"fetchChart":_vm.fetchChart}}):_c('h2',{staticClass:"text-center"},[_vm._v("ไม่มีข้อมูลผลการติดต่อลูกค้า")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }