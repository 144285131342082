var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{attrs:{"gutter":20}},[_c('el-col',{staticClass:"mg-t-5",attrs:{"span":24}},[_c('div',{staticClass:"bg-white pd-y-5 pd-x-6"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('h2',{staticClass:"mg-0"},[_vm._v("นำเสนอ")]),_c('div',{staticStyle:{"width":"100%","overflow-x":"auto"}},[(_vm.dataOffer.length > 0)?_c('BarCharts',{attrs:{"showName":false,"width":_vm.widthMax < 1200 ? `1120px` : '',"barWidth":`25px`,"label":_vm.dataOffer[0].name,"height":'300px',"data":_vm.dataOffer[0].data,"fetchChart":_vm.fetchChart}}):_c('h2',{staticClass:"text-center"},[_vm._v("ไม่มีข้อมูลนำเสนอ")])],1)])],1)],1)]),_c('el-col',{staticClass:"mg-t-5",attrs:{"span":24}},[_c('div',{staticClass:"bg-white pd-y-5 pd-x-6"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('h2',{staticClass:"mg-0"},[_vm._v("สนใจ")]),_c('div',{staticStyle:{"width":"100%","overflow-x":"auto"}},[(_vm.dataInterest.length > 0)?_c('BarCharts',{attrs:{"showName":false,"width":_vm.widthMax < 1200 ? `1120px` : '',"barWidth":`25px`,"label":_vm.dataInterest[0].name,"height":'300px',"data":_vm.dataInterest[0].data,"fetchChart":_vm.fetchChart}}):_c('h2',{staticClass:"text-center"},[_vm._v("ไม่มีข้อมูลที่สนใจ")])],1)])],1)],1)]),_c('el-col',{staticClass:"mg-t-5",attrs:{"span":24}},[_c('div',{staticClass:"bg-white pd-y-5 pd-x-6"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('h2',{staticClass:"mg-0"},[_vm._v("สถานะ Pipeline")]),_c('div',{staticStyle:{"width":"100%","overflow-x":"auto"}},[(_vm.dataPipeline.length > 0)?_c('BarChartsGroupCustom',{attrs:{"productName":[
                'อยู่ระหว่างการพิจารณา/ รอข้อมูลจากลูกค้า',
                'ได้รับเอกสารและข้อมูลเบื้องต้น',
                'อนุมัติกรมธรรม์',
              ],"width":_vm.widthMax < 1200 ? `1120px` : '',"data":_vm.dataPipeline,"barWidth":`25px`,"height":'300px',"color":_vm.color,"fetchChart":_vm.fetchChart,"pageType":"tabIndustry"}}):_c('h2',{staticClass:"text-center"},[_vm._v("ไม่มีข้อมูลสถานะ Pipeline")])],1)])],1)],1)]),_c('el-col',{staticClass:"mg-t-5",attrs:{"span":24}},[_c('div',{staticClass:"bg-white pd-y-5 pd-x-6"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('h2',{staticClass:"mg-0"},[_vm._v("ผลการติดต่อลูกค้า")]),_c('div',{staticStyle:{"width":"100%","overflow-x":"auto"}},[(_vm.dataContactStatus.length > 0)?_c('BarChartsGroupCustom',{attrs:{"productName":[
                'ติดต่อไม่ได้',
                'ไม่สนใจบริการ',
                'ขาดการติดต่อเกิน 3 เดือน',
              ],"width":_vm.widthMax < 1200 ? `1120px` : '',"data":_vm.dataContactStatus,"barWidth":`25px`,"height":'300px',"color":_vm.color,"fetchChart":_vm.fetchChart,"pageType":"tabIndustry"}}):_c('h2',{staticClass:"text-center"},[_vm._v("ไม่มีข้อมูลผลการติดต่อลูกค้า")])],1)])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }