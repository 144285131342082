<template>
  <el-row :gutter="20" class="row-custom-dashboard">
    <el-col :span="24" :md="12" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20" class="row-custom-dashboard in-side">
          <el-col :span="24">
            <h2 class="mg-0">นำเสนอ</h2>
            <BarCharts
              :barWidth="'50px'"
              :label="['Small', 'Medium', 'Large']"
              :height="'300px'"
              :data="dataOffer"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataOffer.length > 0"
            />
            <div v-else class="nodata">
              <h2 class="mg-0">ไม่มีข้อมูลนำเสนอ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" :md="12" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20" class="row-custom-dashboard in-side">
          <el-col :span="24">
            <h2 class="mg-0">สนใจ</h2>
            <BarCharts
              :barWidth="'50px'"
              :label="['Small', 'Medium', 'Large']"
              :height="'300px'"
              :data="dataInterest"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataInterest.length > 0"
            />
            <div v-else class="nodata">
              <h2 class="mg-0">ไม่มีข้อมูลที่สนใจ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">สถานะ Pipeline</h2>
            <BarChartsCustom
              :data="dataPipeline"
              :height="'300px'"
              :label="['Small', 'Medium', 'Large']"
              :productName="[
                'อยู่ระหว่างการพิจารณา/ รอข้อมูลจากลูกค้า',
                'ได้รับเอกสารและข้อมูลเบื้องต้น',
                'อนุมัติกรมธรรม์',
              ]"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataPipeline.length > 0"
            />
            <h2 v-else class="text-center">ไม่มีข้อมูลสถานะ Pipeline</h2>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">ผลการติดต่อลูกค้า</h2>
            <BarChartsCustom
              :data="dataContactStatus"
              :height="'300px'"
              :label="['Small', 'Medium', 'Large']"
              :productName="[
                'ติดต่อไม่ได้',
                'ไม่สนใจบริการ',
                'ขาดการติดต่อเกิน 3 เดือน',
              ]"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataContactStatus.length > 0"
            />
            <h2 v-else class="text-center">ไม่มีข้อมูลผลการติดต่อลูกค้า</h2>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import BarCharts from "@/charts/BarCharts";
import BarChartsGroup from "@/charts/BarChartsGroup";
import BarChartsCustom from "@/charts/BarChartsCustom";

import { colorChart } from "@/colors/color.json";
import { mapState } from "vuex";

export default {
  // props: ["fetchChart", "dataFetch"],
  props: {
    fetchChart: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BarChartsGroup,
    BarChartsCustom,
    BarCharts,
  },
  data() {
    return {
      color: ["#FAC858", "#5470C6", "#FF9900"],
      colorChart: colorChart,
      dataOffer: [],
      dataInterest: [],
      dataStatusApprove: [],
      dataPipeline: [],
      dataContactStatus: [],
    };
  },
  mounted() {
    this.setData();
  },
  watch: {
    insurance() {
      this.setData();
    },
  },
  methods: {
    async setData() {
      await this.setDataOffers();
      await this.setDataInterest();
      await this.setDataPipeline();
      await this.setDataContactStatus();
    },
    async setDataOffers() {
      // this.dataOffer = [];
      // let offers =
      //   typeof this.insurance.offers == "object" ? : null;
      // let total = 0;

      // let arrIndex = [];
      // let arrAll = [];
      // let offerUnique = [];

      // let objSizeS = 0;
      // let objSizeM = 0;
      // let objSizeL = 0;

      // this.dataFetch.offers.forEach((e) => {
      //   var findByMail = offerUnique.find(
      //     (u) => u.email == e.email && u.juristicId == e.juristicId
      //   );
      //   if (!findByMail) {
      //     offerUnique.push({
      //       email: e.email,
      //       juristicId: e.juristicId,
      //     });

      //     if (e.businessSize.toLowerCase() == "s") {
      //       objSizeS += 1;
      //     } else if (e.businessSize.toLowerCase() == "m") {
      //       objSizeM += 1;
      //     } else if (e.businessSize.toLowerCase() == "l") {
      //       objSizeL += 1;
      //     }
      //   }

      // let checkIndexOf = arrIndex.indexOf(e.juristicId);
      // if (checkIndexOf <script 0) {
      //   arrIndex.push(e.juristicId);
      //   arrAll.push(e);
      // }
      // });

      // let objSizeS = arrAll.filter((e) => e.businessSize.toLowerCase() == "s");
      // let objSizeM = arrAll.filter((e) => e.businessSize.toLowerCase() == "m");
      // let objSizeL = arrAll.filter((e) => e.businessSize.toLowerCase() == "l");

      // if (objSizeS > 0 || objSizeM > 0 || objSizeL > 0) {
      //   setObj[0].data.push(objSizeS, objSizeM, objSizeL);
      //   // setObj[0].total = objSizeS + objSizeM + objSizeL;
      //   setObj[0].total = await offers.reduce(
      //     (sum, row) => (sum += row.value),
      //     0
      //   );

      //   console.log("dataOffers:", setObj[0]);
      //   this.dataOffer = setObj;
      // }

      //   if (objSizeS.length > 0 || objSizeM.length > 0 || objSizeL.length > 0) {
      //   setObj[0].data.push(objSizeS.length, objSizeM.length, objSizeL.length);
      //   setObj[0].total = objSizeS.length + objSizeM.length + objSizeL.length;
      //   this.dataOffer = setObj;
      // }

      let setObj = [
        {
          data: [],
          name: [],
          total: 0,
        },
      ];
      let offer = !!this.insurance.offers
        ? this.insurance.offers
        : { S: 0, M: 0, L: 0, total: 0 };

      setObj[0].data.push(offer.S, offer.M, offer.L);
      setObj[0].total = offer.total;
      this.dataOffer = setObj;
    },
    async setDataInterest() {
      let setObj = [
        {
          data: [],
          name: [],
          total: 0,
        },
      ];

      let interest = await this.insurance.interests;
      if (!!interest) {
        let { S, M, L, total } = interest;
        setObj[0].data.push(S, M, L);
        setObj[0].total = total;
        this.dataInterest = setObj;
      } else {
        this.dataInterest = [];
      }

      // let objSizeS = this.dataFetch.interests.filter(
      //   (e) => e.businessSize.toLowerCase() == "s"
      // );
      // let objSizeM = this.dataFetch.interests.filter(
      //   (e) => e.businessSize.toLowerCase() == "m"
      // );
      // let objSizeL = this.dataFetch.interests.filter(
      //   (e) => e.businessSize.toLowerCase() == "l"
      // );
      // if (this.dataFetch.interests.length > 0) {
      //   if (objSizeS.length > 0 || objSizeM.length > 0 || objSizeL.length > 0) {
      //     setObj[0].data.push(
      //       objSizeS.length,
      //       objSizeM.length,
      //       objSizeL.length
      //     );

      //     setObj[0].total = objSizeS.length + objSizeM.length + objSizeL.length;
      //     this.dataInterest = setObj;
      //   }
      // } else {
      //   this.dataInterest = [];
      // }
    },
    // setDataApprove() {
    //   this.dataStatusApprove = [];
    //   let values = Object.values(this.dataFetch.statusApprove);
    //   let arrKey = Object.keys(this.dataFetch.statusApprove);
    //   let setObj = [];
    //   let total = 0;
    //   arrKey.forEach((row, index) => {
    //     if (
    //       values[index].approve > 0 ||
    //       values[index].pending > 0 ||
    //       values[index].reject > 0
    //     ) {
    //       setObj.push({
    //         product: row,
    //         อนุมัติ: values[index].approve,
    //         อนุมัติมีเงื่อนไข: values[index].pending,
    //         ไม่อนุมัติ: values[index].reject,
    //       });
    //     }
    //     total +=
    //       values[index].approve + values[index].pending + values[index].reject;
    //   });
    //   if (total == 0) {
    //     this.dataStatusApprove = [];
    //   } else {
    //     this.dataStatusApprove = setObj;
    //   }
    // },
    setDataPipeline() {
      let pipeline = this.insurance.statusApprove;
      if (!!pipeline) {
        // business size.
        const small = Object.values(pipeline.S);
        const medium = Object.values(pipeline.M);
        const large = Object.values(pipeline.L);
        if (
          small.reduce((sum, val) => (sum += val), 0) > 0 &&
          medium.reduce((sum, val) => (sum += val), 0) > 0 &&
          large.reduce((sum, val) => (sum += val), 0)
        ) {
          let setObj = {
            objSizeS: small,
            objSizeM: medium,
            objSizeL: large,
          };

          this.dataPipeline.push(setObj);
        }
      } else {
        this.dataPipeline = [];
      }

      // let objSizeS = this.dataFetch.statusApprove.filter(
      //   (e) => e.businessSize.toLowerCase() == "s" && e.pipelineStatus != null
      // );
      // let objSizeM = this.dataFetch.statusApprove.filter(
      //   (e) => e.businessSize.toLowerCase() == "m" && e.pipelineStatus != null
      // );
      // let objSizeL = this.dataFetch.statusApprove.filter(
      //   (e) => e.businessSize.toLowerCase() == "l" && e.pipelineStatus != null
      // );

      // let tabS1 = objSizeS.filter((e) => e.pipelineStatus == 1);
      // let tabS2 = objSizeS.filter((e) => e.pipelineStatus == 2);
      // let tabS3 = objSizeS.filter((e) => e.pipelineStatus == 3);

      // let tabM1 = objSizeM.filter((e) => e.pipelineStatus == 1);
      // let tabM2 = objSizeM.filter((e) => e.pipelineStatus == 2);
      // let tabM3 = objSizeM.filter((e) => e.pipelineStatus == 3);

      // let tabL1 = objSizeL.filter((e) => e.pipelineStatus == 1);
      // let tabL2 = objSizeL.filter((e) => e.pipelineStatus == 2);
      // let tabL3 = objSizeL.filter((e) => e.pipelineStatus == 3);
      // let setObj = {
      //   objSizeS: [tabS1.length, tabS2.length, tabS3.length],
      //   objSizeM: [tabM1.length, tabM2.length, tabM3.length],
      //   objSizeL: [tabL1.length, tabL2.length, tabL3.length],
      // };
      // if (
      //   objSizeS.some((e) => e != 0) ||
      //   objSizeM.some((e) => e != 0) ||
      //   objSizeL.some((e) => e != 0)
      // ) {
      //   this.dataPipeline.push(setObj);
      // } else {
      //   this.dataPipeline = [];
      // }
    },
    async setDataContactStatus() {
      let contact = this.insurance.contact;
      if (!!contact) {
        let small = Object.values(contact.S);
        let medium = Object.values(contact.M);
        let large = Object.values(contact.L);

        if (
          small.reduce((sum, val) => (sum += val), 0) > 0 &&
          medium.reduce((sum, val) => (sum += val), 0) > 0 &&
          large.reduce((sum, val) => (sum += val), 0) > 0
        ) {
          let setObj = {
            objSizeS: small,
            objSizeM: medium,
            objSizeL: large,
          };
          this.dataContactStatus.push(setObj);
          return;
        }
      } else {
        this.dataContactStatus = [];
        return;
      }
      // this.dataContactStatus = [];
      // let objSizeS = this.dataFetch.contact.filter(
      //   (e) => e.businessSize.toLowerCase() == "s" && e.contactStatus != null
      // );
      // let objSizeM = this.dataFetch.contact.filter(
      //   (e) => e.businessSize.toLowerCase() == "m" && e.contactStatus != null
      // );
      // let objSizeL = this.dataFetch.contact.filter(
      //   (e) => e.businessSize.toLowerCase() == "l" && e.contactStatus != null
      // );

      // let tabS1 = objSizeS.filter((e) => e.contactStatus == 1);
      // let tabS2 = objSizeS.filter((e) => e.contactStatus == 2);
      // let tabS3 = objSizeS.filter((e) => e.contactStatus == 3);

      // let tabM1 = objSizeM.filter((e) => e.contactStatus == 1);
      // let tabM2 = objSizeM.filter((e) => e.contactStatus == 2);
      // let tabM3 = objSizeM.filter((e) => e.contactStatus == 3);

      // let tabL1 = objSizeL.filter((e) => e.contactStatus == 1);
      // let tabL2 = objSizeL.filter((e) => e.contactStatus == 2);
      // let tabL3 = objSizeL.filter((e) => e.contactStatus == 3);

      // let setObj = {
      //   objSizeS: [tabS1.length, tabS2.length, tabS3.length],
      //   objSizeM: [tabM1.length, tabM2.length, tabM3.length],
      //   objSizeL: [tabL1.length, tabL2.length, tabL3.length],
      // };
      // if (
      //   objSizeS.some((e) => e != 0) ||
      //   objSizeM.some((e) => e != 0) ||
      //   objSizeL.some((e) => e != 0)
      // ) {
      //   this.dataContactStatus.push(setObj);
      // } else {
      //   this.dataContactStatus = [];
      // }
    },
  },
  computed: {
    ...mapState({
      insurance: (state) => state.dashboard.insurance,
    }),
  },
};
</script>
