<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>
<script>
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 2000;
export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    barWidth: {
      type: String,
      default: "50px",
    },
    height: {
      type: String,
      default: "300px",
    },
    pageType: {
      type: String,
      default: "",
    },
    label: {
      type: Array,
      default: () => [],
    },
    productName: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
    fetchChart: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fetchChart() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
      setTimeout(() => {
        this.initChart();
      }, 400);
    },
  },
  data() {
    return {
      chart: null,
      checkChart: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        legend: {
          selectedMode: false,
        },
        tooltip: {
          // trigger: "axis",
          textStyle: {
            color: "#fff",
            fontSize: 16,
          },
          formatter: (params) => {
            var output =
              params.seriesName + "<br/>" + params.marker + params.name;
            output += "<span class='pd-l-6'>" + params.value + "  ราย </span>";
            return output;
          },
        },
        textStyle: {
          fontFamily: "DBHeavent",
          fontSize: 18,
          color: "#000",
        },
        grid: {
          left: "1%",
          right: "1%",
          bottom: "3%",
          containLabel: true,
        },
        // dataset: {
        // dimensions: ["product", "อยู่ระหว่างการพิจารณา/ รอข้อมูลจากลูกค้า", "ได้รับเอกสารและข้อมูลเบื้องต้น", "อนุมัติกรมธรรม์"],
        // source: this.data,
        // },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 1)",
            },
          },
          data: this.label,
          // data: this.label.name,
          axisTick: {
            alignWithLabel: true,
          },
          nameTextStyle: {
            overflow: this.pageType == "tabIndustry" ? "truncate" : "",
          },
          axisLabel: {
            overflow: this.pageType == "tabIndustry" ? "truncate" : "",
            rotate: this.pageType == "tabIndustry" ? 30 : 0,
            fontSize: 18,
          },
        },
        yAxis: {
          axisLabel: {
            fontSize: 18,
            formatter: (params) => {
              let output = "";
              let findDot = params.toString().includes(".");
              if (!findDot) {
                output = params;
              }
              return output;
            },
          },
        },
        series: [
          {
            name: this.productName[0],
            type: "bar",
            itemStyle: {
              color: "#3598FF",
            },
            barGap: "0%",
            barWidth: this.barWidth,
            data: [
              this.data[0].objSizeS[0],
              this.data[0].objSizeM[0],
              this.data[0].objSizeL[0],
            ],
          },
          {
            name: this.productName[1],
            type: "bar",
            itemStyle: {
              color: "#FF646A",
            },
            barGap: "0%",
            barWidth: this.barWidth,
            data: [
              this.data[0].objSizeS[1],
              this.data[0].objSizeM[1],
              this.data[0].objSizeL[1],
            ],
          },
          {
            name: this.productName[2],
            type: "bar",
            itemStyle: {
              color: "#38D85B",
            },
            barGap: "0%",
            barWidth: this.barWidth,
            data: [
              this.data[0].objSizeS[2],
              this.data[0].objSizeM[2],
              this.data[0].objSizeL[2],
            ],
          },
        ],
      });
    },
  },
};
</script>
